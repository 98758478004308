import DestinationModalContinentList from 'components/modals/frontend/Destinations/DestinationModalContinentList'
import { isServer } from 'data/helpers/ssr'
import { graphql } from 'gql'
import { useState } from 'react'
import { useQuery } from 'urql'
import DestinationCountryAccordion from './DestinationCountryAccordion'
import { LoadingIcon } from 'components/icons'
import { useDestinationSearch } from 'data/hooks/Destinations/useDestinationSearch'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import SearchResults from 'components/popovers/DestinationSelectionPopover/_SearchResults'
import { Controller, useFormContext } from 'react-hook-form'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import classNames from 'classnames'

const DESTINATION_SELECTION_QUERY = graphql(`
  query DestinationSelectionCountriesData($filters: CountriesFilterInput) {
    countries(first: 100, filters: $filters) {
      data {
        id
        value
        slug
      }
    }
  }
`)

export type ControlledDestinationsSelectionsFormFields = {
  destination: SelectedDestinationData | undefined
  destination_search: string | undefined
}

interface ControlledDestinationsSelectionsProps {
  pause: boolean
  onSelect: () => void
  redirect?: boolean
  padding: 'md' | 'lg'
}

export default function ControlledDestinationsSelections ({
  pause,
  onSelect,
  redirect,
  padding,
}: ControlledDestinationsSelectionsProps) {
  const { control, watch, setValue } = useFormContext<ControlledDestinationsSelectionsFormFields>()

  const [continentId, setContinentId] = useState<string | undefined>(undefined)

  const [{ data, fetching }] = useQuery({
    query: DESTINATION_SELECTION_QUERY,
    pause: pause || isServer,
    variables: {
      filters: {
        continent_id: continentId,
      },
    },
  })

  const { results: destinationSearchResults, isSearchResults } = useDestinationSearch(watch('destination_search'))

  return (
    <Controller
      name="destination"
      control={control}
      render={({ field: { onChange } }) => (
        <>
          {
            destinationSearchResults && (
              <div className="flex flex-col overflow-y-auto">
                <SectionHeaders.MD className={classNames(
                  'text-grey-650',
                  {
                    'px-24': padding === 'md',
                    'px-40': padding === 'lg',
                  },
                )}>
                  {isSearchResults
                    ? 'Suggestions'
                    : 'Popular Destinations'
                  }
                </SectionHeaders.MD>


                <SearchResults
                  className={{
                    container: classNames(
                      'pt-14',
                      {
                        'px-24': padding === 'md',
                        'px-40': padding === 'lg',
                      },
                    ),
                  }}
                  onClick={(data) => {
                    onChange(data)
                    setValue('destination_search', data.value)
                    onSelect()
                  }}
                  destinations={destinationSearchResults}
                />
              </div>
            )
          }


          <SectionHeaders.MD className={classNames(
            'text-grey-650',
            {
              'px-24': padding === 'md',
              'px-40': padding === 'lg',
            },
          )}>
            All Destinations
          </SectionHeaders.MD>

          <DestinationModalContinentList
            pause={pause}
            continentId={continentId}
            setContinentId={setContinentId}
            className={{
              container: 'pt-20 pb-10',
            }}
            slideOffset={padding === 'md' ? 24 : 40}
          />

          {
            (fetching && !data) && (
              <div className="flex justify-center py-50">
                <LoadingIcon />
              </div>
            )
          }

          <div className="flex flex-col h-full max-h-[342px] overflow-y-auto">
            {
              data?.countries.data.map((country) => (
                <DestinationCountryAccordion
                  redirect={redirect}
                  onSelect={(data: SelectedDestinationData) => {
                    onChange(data)
                    setValue('destination_search', data.value)
                    onSelect()
                  }}
                  key={country.id}
                  pause={pause}
                  country={country}
                  className={{
                    container: classNames({
                      'px-24': padding === 'md',
                      'px-40': padding === 'lg',
                    }),
                  }}
                />
              ))
            }
          </div>
        </>
      )}
    />
  )
}
